import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const renderPost: any = post => {
  const title = post.frontmatter.title || post.fields.slug

  return (
    <li key={post.fields.slug}>
      <article
        className="post-list-item"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1>
            <Link to={post.fields.slug} itemProp="url">
              <span itemProp="headline">{title}</span>
            </Link>
          </h1>
          <small>
            {post.frontmatter.date} &nbsp;·&nbsp;
            {post.fields.readingTime.text}
          </small>
        </header>
        <section>
          <p
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
            itemProp="description"
          />
        </section>
      </article>
    </li>
  )
}
const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes
  console.log(posts)
  posts.sort((a, b) => {
    const aWeight = a.frontmatter.weight || 0
    const bWeight = b.frontmatter.weight || 0
    if (aWeight > bWeight) {
      return -1
    }
    if (aWeight < bWeight) {
      return 1
    }
    return a.frontmatter.date > b.frontmatter.date ? -1 : 1
  })

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Zxch3n's Blog" />
      <Bio />
      <ol style={{ listStyle: `none` }}>{posts.map(renderPost)}</ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          weight
        }
      }
    }
  }
`
